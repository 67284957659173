@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");

html {
  background-color: #f5fffa;
}
.container {
  margin: 10px;
}
.background {
  background-color: #f5fffa;
  width: 100%;
  height: 100%;
}
.title {
  margin-left: 40px;
  font-size: 30px;
  margin-bottom: -15px;
  /* margin-top: -5px; */
}
.tableHead {
  margin: -3px;
  font-weight: bold;
}
.blue {
  background: #f0f8ff;
}
.headerColor {
  background: #70c69d;
}
.headerText {
  margin-left: 30px;
  margin-top: 20px;
  font-size: 35px;
  font-weight: bold;
  font-family: "ヒラギノ丸ゴ ProN", "Hiragino Maru Gothic ProN";
}
.headerSubText {
  margin-top: -33px;
  margin-left: 30px;
  font-family: "ヒラギノ丸ゴ ProN", "Hiragino Maru Gothic ProN";
}
.hota {
  padding: -40px;
  margin-top: 15px;
  margin-right: 7px;
  margin-left: 20px;
}
.dialogHota {
  margin-bottom: -10px;
  margin-right: 5px;
}
.footerColor {
  background: #69b9ff;
}
.grey {
  background: #a7a7a7;
}
.odd {
  background: rgb(195, 214, 248);
}
.even {
  background: #e4f3ff;
}
.normalNumber {
  margin: -5px;
  /* width: 10px; */
}
.normalPushNumber {
  color: #3790ea;
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
  margin: -5px;
  /* width: 10px; */
}
.normalPushNumber:hover {
  cursor: pointer;
}
.exceptPushNumber {
  color: rgb(255, 0, 0);
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
  margin: -5px;
  /* width: 10px; */
}
.exceptPushNumber:hover {
  cursor: pointer;
}
.unapprovedTable {
  margin-bottom: 20px;
  margin-top: 10px;
}
.handleButton {
  margin: 10px;
}
.driverDialog {
  width: 2000px;
  height: 800px;
}
.pushIcon {
  margin-top: 10px;
  margin-right: 5px;
}
.pushIcon:hover {
  cursor: pointer;
}
.fontRed {
  color: red;
}
.fontBlue {
  color: blue;
}
.buttonText {
  line-height: 3px;
}
.confirmText {
  font-size: 21px;
  color: black;
  margin-bottom: -5px;
}
.alertText {
  color: red;
}
.centerText {
  text-align: center;
}
.dialogText {
  text-align: center;
  background: linear-gradient(transparent 60%, yellow 30%);
  font-size: large;
}
.backButton {
  text-decoration: underline;
  font-size: 17px;
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: 15px;
}
.groupName {
  font-size: 30px;
}
.accountInfo {
  margin-left: 30px;
}
.accountInfo_name {
  margin-left: 30px;
  margin-top: 35px;
  margin-bottom: 10px;
}
.groupNames {
  margin-left: 30px;
  margin-bottom: -5px;
}
.errorText {
  font-size: medium;
}
